<template>
  <div>
    <a-row type="flex" style="flex-wrap: nowrap">
      <a-col flex="300px" :span="6">
        <a-button
            type="link"
            icon="folder-add"
            @click="create"
            block
            style="font-size: 18px; height: auto; padding: 16px; text-align: left"
        >新建分组
        </a-button
        >
        <favorite-groups
            ref="favoriteGroups"
            :type="1"
            @select="onSelect"
            style="max-height: 760px; overflow: auto"
        ></favorite-groups>
      </a-col>
      <a-col flex="auto" style="padding: 16px;width: 1000px;overflow-x: auto">
        <a-row type="flex" style="padding-bottom: 16px">
          <a-col flex="auto">
            <div style="display: flex">
              <div>
                P2授权：
                <a-select
                    v-model="query.param.p2OauthStatus"
                    placeholder="请选择"
                    style="width: 160px"
                >
                  <a-select-option key=""> 全部</a-select-option>
                  <a-select-option key="0"> 未授权</a-select-option>
                  <a-select-option key="1"> 已授权</a-select-option>
                  <a-select-option key="2"> 授权过期</a-select-option>
                  <a-select-option key="3"> 处理中</a-select-option>
                </a-select>
              </div>
              <div style="padding-left: 2%">
                P1授权：
                <a-select
                    v-model="query.param.p1OauthStatus"
                    placeholder="请选择"
                    style="width: 160px"
                >
                  <a-select-option key=""> 全部</a-select-option>
                  <a-select-option key="0"> 未授权</a-select-option>
                  <a-select-option key="1"> 已授权</a-select-option>
                  <a-select-option key="2"> 授权过期</a-select-option>
                </a-select>
              </div>
              <a-input
                  style="width: 300px;margin-left: 20px"
                  placeholder="请输入抖音昵称/经销商名称/公司名称"
                  v-model="query.param.nickname"/>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="16"></a-col>
          <a-col :span="6">
            <div class="tools">
              <!--  <a-input-search
                    style="width: 300px;margin-right: 20px"
                    enter-button="搜索"
                    placeholder="请输入昵称"
                    v-model="nickname" @search="onSearch"/>-->
              <a-button type="primary" @click="onSearch">搜索</a-button>
              <a-button
                  type="primary"
                  style="margin: 0 20px"
                  :disabled="
                  !this.query.param.groupId || !(selGroup.bizCount || {}).count
                "
              >
                <a href="javascript:;" @click="handleDownload">导出</a>
              </a-button>
              <a-button
                  type="primary"
                  :disabled="
                  !this.query.param.groupId || !(selGroup.bizCount || {}).count
                "
              >
                <router-link
                    :to="{
                    name: 'favorites:authorAnalysis',
                    params: { groupId: query.param.groupId || '0' },
                  }"
                    target="_blank"
                >组内分析
                </router-link
                >
              </a-button>
            </div>
          </a-col>
        </a-row>
        <v-table
            ref="table"
            :columns="columns"
            :remote="remote"
            :query="query"
            :pagination="pagination"
            row-key="authorId"
            :scroll="{ x: 1000,y: 'calc(100vh - 360px)'}"
        >
          <template #code="{ row }">
            <author-card :value="row" :dataType="$root.dataType"></author-card>
          </template>
          <!--<template #open_status="{ row }">
            <a-tag v-if="row.open_status == 0" color="red"> 未授权</a-tag>
            <a-tag v-if="row.open_status == 1" color="green"> 已授权</a-tag>
            <a-tag
                v-if="row.open_status == 2 || row.open_status == 3"
                color="red"
            >
              授权失效
            </a-tag>
            <a-tag
                v-if="row.open_status == 5 || row.open_status == 6"
                color="red"
            >
              授权失败
            </a-tag>
            <a-tag v-if="row.open_status == 4" color="red"> 检查中</a-tag>
          </template>
          <template #service_status="{ row }">
            <a-tag v-if="row.service_status == 2" color="red"> 授权失效</a-tag>
            <a-tag v-if="row.service_status == 1" color="green"> 已授权</a-tag>
            <a-tag v-if="row.service_status == 0" color="red"> 未授权</a-tag>
          </template>-->
          <template #p1OauthStatus="{row}">
        <span>{{
            row.p1OauthStatus == 0 ? "未授权" : row.p1OauthStatus == 1 ? "已授权" : row.p1OauthStatus == 2 ? "授权过期" : "-"
          }}</span>
          </template>
          <template #p2OauthStatus="{row}">
        <span>{{
            row.p2OauthStatus == 0 ? "未授权" : row.p2OauthStatus == 1 || row.p2OauthStatus == 3 ? "已授权" : row.p2OauthStatus == 2 ? "授权过期" : row.p2OauthStatus == 4 ? "处理中" : "-"
          }}</span>
          </template>
          <template #moduleAwemeCount="{row}">
            <span>{{ row.moduleAwemeCount || 0 }}</span>
          </template>
          <template #operation="{ row }">
            <router-link
                :to="{
                name: 'author:analysis',
                params: { authorId: row.authorId },
              }"
                target="_blank"
            >播主分析
            </router-link
            >
            <a-divider type="vertical"/>
            <a type="link" @click="cancelFavorite(row)">取消收藏</a>
            <a-divider type="vertical"/>
            <router-link :to="{ name: 'authorAuth', params: { authorId: row.authorId } }" target="_blank">
              编辑授权
            </router-link>

          </template>
        </v-table>
      </a-col>
    </a-row>
    <group-form
        v-model="visible"
        ref="groupForm"
        @saved="onGroupSaved"
        :type="1"
    ></group-form>
  </div>
</template>
<style scoped>
.ant-table .ant-list-item {
  padding: 0;
}
</style>
<script>
import AuthorCard from "@/components/widgets/author-card";
import FavoriteGroups from "@/components/widgets/favorite-groups";
import GroupForm from "@/components/widgets/group-form";
import uploadExcel from "@/utils/uploadExcel.js";

let defaultQuery = {
  orderBys: "ctime|desc,authorId|desc",
  param: {
    groupId: "",
    nickname: "",
    p2OauthStatus: "",
    p1OauthStatus: "",
  }
};
export default {
  components: {GroupForm, AuthorCard, FavoriteGroups},
  data() {
    return {
      orderDateRange: [],
      query: _.cloneDeep(defaultQuery),
      loading: false,
      loadingSummary: false,
      pagination: {pageSize: 10},
      visible: false,
      selGroup: {},
      downloadUrl: "",
      nickname: ""
    };
  },
  computed: {
    columns() {
      return [
        {
          title: `${ this.$root.dataTypeLabel }号`,
          dataIndex: "code",
          scopedSlots: {customRender: "code"},
          ellipsis: true,
          width: 300
        },
        {
          title: "互动数据（P2）",
          dataIndex: "p2OauthStatus",
          scopedSlots: {customRender: "p2OauthStatus"},
          width: 100
        },
        {
          title: "直播数据（P1）",
          dataIndex: "p1OauthStatus",
          scopedSlots: {customRender: "p1OauthStatus"},
          width: 100
        },
        {
          title: "粉丝数",
          dataIndex: "fansCountTotal",
          customRender: this.$dict.humanReadable,
          width: 80
        },
        {
          title: "作品数",
          dataIndex: "awemeCountTotal",
          customRender: this.$dict.humanReadable,
          width: 80
        },
        {
          title: "模板作品数",
          dataIndex: "moduleAwemeCount",
          scopedSlots: {customRender: "moduleAwemeCount"},
          width: 100
        },
        {
          title: "直播场次",
          dataIndex: "authorActionLastNDay.liveTotalCountAll",
          customRender: this.$dict.humanReadable,
          width: 80
        },
        {
          title: "操作",
          scopedSlots: {customRender: "operation"},
          width: 120
        }
      ];
    }
  },
  methods: {
    create() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.groupForm.$emit("init", {});
      });
    },
    onGroupSaved() {
      this.$refs.favoriteGroups.load();
    },
    onSelect(group = {}) {
      this.nickname = "";
      this.selGroup = group || {};
      this.query.param.groupId = group.id || 0;
      group.id ? this.search() : this.clean();
    },
    cancelFavorite(row) {
      this.$confirm({
        title: "取消收藏确认",
        content: () => (
            <div style="color:red;font-size: 15px;padding: 8px 0">
              您确定要取消收藏该播主吗?
            </div>
        ),
        onOk: () => {
          this.$api.FavoriteService.unFavorite(
              row.authorId,
              this.query.param.groupId
          ).then(() => {
            this.$message.info("取消成功！");
            this.search();
            this.$refs.favoriteGroups.load();
          });
        }
      });
    },
    init(options = {}) {
      this.$refs.favoriteGroups.load();
    },
    remote(qp) {
      let favoriteObj = {};
      let authorArray = [];
      return this.$api.FavoriteService.findFavoriteBiz(1, qp)
          .then((res) => {
            favoriteObj = res;
            authorArray = res.data.map((item) => item.authorId);
            return this.$api.FavoriteService.findFavoriteBizStatu(authorArray);
          })
          .then((statuRes) => {
            if(authorArray.length > 0) {
              let {open_status, service_status} = statuRes;
              let open_status_map = {},
                  service_status_map = {};
              open_status.forEach((item) => {
                open_status_map[item.author_id] = item.oauth_status;
              });
              service_status.forEach((item) => {
                service_status_map[item.author_id] = item.status;
              });
              favoriteObj.data.forEach((item) => {
                item.open_status = open_status_map[item.authorId] || "0";
                item.service_status = service_status_map[item.authorId] || "0";
              });
            }
            return favoriteObj;
          });
    },
    search(reset = true) {
      this.$refs.table.load({reset: reset});
    },
    clean() {
      this.$refs.table.clean();
    },
    handleDownload() {
      let groupId = this.selGroup.id;
      return uploadExcel(
          "/creator/exportExcel",
          {
            dayOffset: 30,
            groupId,
            type: 1
          },
          "播主资料"
      );
    },
    gotoAuth(row) {

    },
    onSearch() {
      // this.query.param.nickname = this.nickname;
      this.query.limit = 10;
      this.query.start = 0;
      this.search();
    } // 搜索
  },
  created() {
  },
  mounted() {
    this.$on("init", this.init);
  }
};
</script>
<style lang="less" scoped>
.tools {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
</style>
